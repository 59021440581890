import Toastify from 'toastify-js';

export function showNotification(message, type = 'neutral') {
    let className = 'z-[100] p-4 border text-sm rounded-lg';

    switch (type) {
        case 'success':
            className += ' border-green-300 bg-green-50';
            break;
        case 'failure':
            className += ' border-red-300 bg-red-50';
            break;
        case 'neutral':
        default:
            className += ' border-black bg-white';
            break;
    }

    const toast = Toastify({
        text: message,
        duration: 5000,
        position: 'left',
        className: className,
        offset: {
            x: 15,
            y: 15
        },
        onClick: () => toast.hideToast(),
    }).showToast();
}

export function setFlashCloseEvent() {
    const notification = document.querySelector('.toastify');

    if (!notification) {
        return;
    }

    // Close on click
    notification.addEventListener('click', () => {
        notification.classList.add('hidden');
    })

    window.addEventListener('load', () => {
        const ms = notification.textContent.length > 250 ? 10000 : 5000;

        setTimeout(() => {
            notification.classList.add('hidden');
        }, ms);
    });
}
