/*
 * This file is part of the Sylius package.
 *
 * (c) Sylius Sp. z o.o.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/api';
import $ from 'jquery';
import { showNotification } from '../utils/notification';

$.fn.extend({
  addToCart() {
    const element = this;
    const url = $(element).attr('action');

    element.api({
      method: 'POST',
      on: 'submit',
      cache: false,
      url,
      beforeSend(settings) {
        /* eslint-disable-next-line no-param-reassign */
        settings.data = element.serialize();

        return settings;
      },
      onSuccess() {
        location.reload();
      },
      onFailure(response) {
        let validationMessage = '';
        Object.entries(response.errors.errors).forEach(([, message]) => {
          validationMessage += message;
        });
        showNotification(validationMessage, 'failure');
      },
    });
  },
});
