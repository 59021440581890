/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

function formatAppliedPromotions(appliedPromotions) {
  let appliedPromotionsElement = '';
  $('#appliedPromotions').html('');

  if (appliedPromotions !== '[]') {
    $.each(appliedPromotions, (index, promotion) => {
      let promotionInfo = promotion.label;
      promotionInfo += promotion.description ? ` - ${promotion.description}` : '';
      appliedPromotionsElement += `<div class="ui blue label promotion_label" style="margin: 1rem 0;"><div class="row ui small sylius_catalog_promotion">${promotionInfo}</div></div>`;
    });
    $('#appliedPromotions').html(appliedPromotionsElement);
  }
}

const handleProductOptionsChange = function handleProductOptionsChange() {
  $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', (e) => {



    let selector = '';

    $('#sylius-product-adding-to-cart [data-option]').each((index, element) => {
      const select = $(element);
      const option = select.find('input:checked').val();
      selector += `[data-${select.attr('data-option')}="${option}"]`;

      // Affichage de la composition relative au variant
      if (select.attr('data-option') == 'couleur_greenleaf' ||  select.attr('data-option') == 'rainbow_colors' ) {

        document.querySelectorAll("#product-composition .variant-composition").forEach((element) => {
          element.style.display = "none";
        })
        document.querySelector("#product-composition .variant-composition[data-option-color='" + option + "']").style.display = "block";
      }

    });

    const price = $('#sylius-variants-pricing').find(selector).attr('data-valueWithTaxes');
    const originalPrice = $('#sylius-variants-pricing').find(selector).attr('data-original-price');
    let appliedPromotions = $('#sylius-variants-pricing').find(selector).attr('data-applied_promotions');
    if (appliedPromotions !== undefined) {
      appliedPromotions = JSON.parse(appliedPromotions);
    }

    if (price !== undefined) {

      const productElement = $('#product-price');
      const productDataTTC = productElement.data("ttc") ?? "";

      productElement.text(price + " " + productDataTTC);

      $('button[type=submit]').removeAttr('disabled');

      if (originalPrice !== undefined) {
        $('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
      } else {
        $('#product-original-price').css('display', 'none');
      }

      formatAppliedPromotions(appliedPromotions);
    } else {
      $('#product-price').text($('#sylius-variants-pricing').attr('data-unavailable-text'));
      $('button[type=submit]').attr('disabled', 'disabled');
    }

    const variant = document.querySelector('#sylius-variants-pricing').querySelector(selector);

    if (!variant) {
      return;
    }

    const onHand = document.querySelector('#sylius-variants-pricing').querySelector(selector).getAttribute('data-onhand');
    const tracked = document.querySelector('#sylius-variants-pricing').querySelector(selector).getAttribute('data-tracked');
    const onHandBadge = document.querySelector('[data-js-product-onhand-badge]');
    const onHandText = document.querySelector('[data-js-product-onhand-text]');
    const submitButton = document.querySelector('button[type=submit]');

    if ((tracked === 'true') && (onHand < 1)) {
      onHandBadge.classList.remove('bg-rainbow-green');
      onHandBadge.classList.add('bg-rainbow-red');
      onHandText.textContent = window.location.href.includes('/en/') ? 'Out of stock' : 'Rupture de stock';
      submitButton.setAttribute('disabled', '');
    } else {
      onHandBadge.classList.remove('bg-rainbow-red');
      onHandBadge.classList.add('bg-rainbow-green');
      onHandText.textContent = window.location.href.includes('/en/') ? 'Available' : 'Disponible';
      submitButton.removeAttribute('disabled');
    }
  });
};

const handleProductVariantsChange = function handleProductVariantsChange() {
  $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {

    const priceRow = $(event.currentTarget).parents('tr').find('.sylius-product-variant-price');
    const price = priceRow.text();
    const originalPrice = priceRow.attr('data-original-price');
    let appliedPromotions = priceRow.attr('data-applied-promotions');
    if (appliedPromotions !== '[]') {
      appliedPromotions = JSON.parse(appliedPromotions);
    }

    $('#product-price').text(price);
    formatAppliedPromotions(appliedPromotions);

    if (originalPrice !== undefined) {
      $('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
    } else {
      $('#product-original-price').css('display', 'none');
    }
  });
};

$.fn.extend({
  variantPrices() {
    if ($('#sylius-variants-pricing').length > 0) {
      handleProductOptionsChange();
    } else if ($('#sylius-product-variants').length > 0) {
      handleProductVariantsChange();
    }
  },
});
