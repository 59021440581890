import './shim/shim-jquery';

import 'sylius/ui/app';
import './sylius-address-book';
import './sylius-province-field';
import './sylius-variants-prices';
import SyliusAddressBook from './sylius-address-book';
import './sylius-add-to-cart';

console.log('SYLIUS');

$(($) => {
  $(document).provinceField();
  $(document).variantPrices();

  $('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

  $('#sylius-product-adding-to-cart').addToCart();
});

// Address book
const syliusShippingAddress = document.querySelector('[data-js-address-book="sylius-shipping-address"]');
if (syliusShippingAddress && syliusShippingAddress.querySelector('.dropdown')) {
  SyliusAddressBook(syliusShippingAddress);
}
const syliusBillingAddress = document.querySelector('[data-js-address-book="sylius-billing-address"]');
if (syliusBillingAddress && syliusBillingAddress.querySelector('.dropdown')) {
  SyliusAddressBook(syliusBillingAddress);
}