export function bgImgLazyLoading() {
    // Background image lazy loading
    let lazyloadImages;
    const lazyClass = 'bg_lazy';

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(`.${lazyClass}`);
        let imageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let image = entry.target;
                    image.classList.remove(lazyClass);
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
        });
    } else {
        let lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(`.${lazyClass}`);

        function bgLazyload() {
            if (lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function () {
                let scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function (img) {
                    if (img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove(lazyClass);
                    }
                });
                if (lazyloadImages.length == 0) {
                    document.removeEventListener("scroll", bgLazyload);
                    window.removeEventListener("resize", bgLazyload);
                    window.removeEventListener("orientationChange", bgLazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", bgLazyload);
        window.addEventListener("resize", bgLazyload);
        window.addEventListener("orientationChange", bgLazyload);
    }
}