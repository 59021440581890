import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';


export function handleOwlCarousel(selector) {
    const config = {
        autoplay: true,
        smartSpeed: 1000,
        center: false,
        dots: false,
        loop: true,
        nav : false,
        responsiveClass: true
    };

    if (selector === '.team-carousel') {
        config.dots = true;
        config.margin = 20;
        config.nav = true;
        config.navText = [
            '<div class="icon-gauche"><img src="/media/image/fleche-gauche.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>',
            '<div class="icon-droit"><img src="/media/image/fleche-droite.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>'
        ];
        config.responsive = {
            0:{
                items:1
            },
            768:{
                items:2
            },
            1200:{
                items:3
            },
            1920:{
                items:4
            }
        };

    } else if (selector === '.team-galerie_thumbails') {
        config.margin = 10;
        config.responsive = {
            0:{
                items:2
            },
            768:{
                items:3
            },
            992:{
                items:5
            }
        };
    } else if (selector === '.team-partenaire' || selector === '.certifications-carousel') {

      if (window.innerWidth > 976) {
        config.autoplay = false;
      }

      config.margin = 15;
        config.responsive = {
            0:{
                items:3
            },
            768:{
                items:4
            },
            992:{
                items:6
            },
            1200:{
                items:3
            },
            1920:{
                items:6
            }
        };
    } else if (selector === '.team-recherche') {
      config.margin = 15;
      config.responsive = {
        0:{
          items:1
        },
        768:{
          items:2
        },
        992:{
          items:2
        }
      };
    } else if (selector === '.associations-product') {
      config.margin = 15;
      config.nav = true;
      config.navText = [
        '<div class="icon-gauche"><img src="/media/image/fleche-gauche.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>',
        '<div class="icon-droit"><img src="/media/image/fleche-droite.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>'
      ];
      config.responsive = {
        0:{
          items:1
        },
        768:{
            items:2
        },
        1200:{
            items:3
        },
        1920:{
            items:4
        }
      };
    } else if (selector === '.taxons-list-carousel') {
      config.margin = 40;
      config.nav = true;
      config.navText = [
        '<div class="icon-gauche"><img src="/media/image/fleche-gauche.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>',
        '<div class="icon-droit"><img src="/media/image/fleche-droite.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>'
      ];
      config.responsive = {
        0: {
          items: 2
        },
        1200: {
          items: 4
        },
        1920: {
          items: 5
        }
      };
    } else if (selector === '.taxons-list-carousel-pro') {
      config.margin = 40;
      config.nav = true;
      config.navText = [
        '<div class="icon-gauche"><img src="/media/image/fleche-gauche-blanche.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>',
        '<div class="icon-droit"><img src="/media/image/fleche-droite-blanche.png" loading="lazy" alt="" class="ml-2 h-[47px]"></div>'
      ];
      config.responsive = {
        0: {
          items: 2
        },
        1200: {
          items: 4
        },
        1920: {
          items: 5
        }
      };
    } else if (selector === '.owl-favorites-products') {
      config.autoplay = false;
      config.loop = false;
      config.margin = 20;
      config.autoWidth = true;
    }

    $(selector).owlCarousel(config);
}
