// alert('heihe')
import '../../scss/index.scss';
import '../../scss/hub.scss';

import '../sylius/app.js';
import Glide, { Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { setFlashCloseEvent } from '../utils/notification.js';
import { bgImgLazyLoading } from '../utils/perf.js';
import {handleOwlCarousel} from "../utils/owl-carousel";

console.log('HUB');

setFlashCloseEvent();
bgImgLazyLoading();

handleOwlCarousel('.team-partenaire');
handleOwlCarousel('.certifications-carousel');

/** GROUP SWITCHER */
const groupCheckbox = document.querySelector('.group-switcher input[type="checkbox"]');

if (groupCheckbox) {
    groupCheckbox.addEventListener('change', () => document.location.href = groupCheckbox.checked ?
        groupCheckbox.dataset.proPath :
        groupCheckbox.dataset.partPath
    );
}

// BRANDS
new Glide('.glide', {
    type: 'carousel',
    perView: 5,
    gap: 30,
    breakpoints: {
        835: {
            perView: 3,
        },
        431: {
            perView: 2,
        },
    }
}).mount({ Breakpoints, Swipe });

// GSAP
(() => {
    if (!document.querySelector('.intro div .content p')
        && !document.querySelector('.cta .quote p')
        && !document.querySelector('.certifications .quote p')
        && !document.querySelector('.socials .quote p')
        && !document.querySelector('.rooms .quote p')
    ) {
        return;
    }

    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.intro div .content p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.intro div .content',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });

    gsap.to('.cta .quote p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.cta',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });

    gsap.to('.certifications .quote p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.certifications',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });

    gsap.to('.socials .quote p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.socials',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });

    gsap.to('.rooms .quote p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.rooms div',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });
})();
